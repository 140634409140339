import headerReducer from "./header/headerReducer"
import menuReducer from "./header/MenuReducer";
import searchBarReducer from "./header/SearchBarReducer";
import StockLocatorReducer from "./commun/StockLocatorReducer";
import {HOME_PAGE, PRODUCT_LIST_PAGE, SEARCH_PAGE} from "@/utils/constants";
import trackingReducer from "./tracking/trackingReducer";
import productListReducer from "./productList/productListReducer";
import blockFilterReducer from "./productList/blockFilterReducer";


export const defaultReducers = {
    header: headerReducer,
    menu: menuReducer,
    searchBar: searchBarReducer,
    stockLocator: StockLocatorReducer,
    tracking: trackingReducer,

}

export const productListReducers = {
    productList: productListReducer,
    blockFilter: blockFilterReducer
}

export const getReducerByContext = (context) => {
    switch (context) {
        case HOME_PAGE:
            return defaultReducers;
        case PRODUCT_LIST_PAGE:
        case SEARCH_PAGE:
            return productListReducers
        default:
            return defaultReducers;
    }
}
