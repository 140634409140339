import React from 'react'
import Utils from "@/utils/Default/Utils";
import Event from "./Event";

const Events = ({shopEventList, shopShortName, context}) => {
    return shopEventList.slice(0, 2).map((shopEvent, index) => {
            let currentDate = Utils.getFormatedCurrentDate();
            let startDateOfDisplay = Utils.getMetaInfosValueByCode("startDateOfDisplay", shopEvent.displayingInfos)
            if (context === 'animCoHomePage' || (shopEvent.typeId !== '6' && shopEvent.typeId !== '3') && startDateOfDisplay !== '' && startDateOfDisplay <= currentDate) {
                return (
                    <Event key={index}
                           src={Utils.getMetaInfosValueByCode("tplLinkDesktopHP", shopEvent.displayingInfos)}
                           displayingInfos={shopEvent.displayingInfos}
                           displayText={shopEvent.displayText}
                           label={shopEvent.label}
                           typeId={shopEvent.typeId}
                           shortDescription={shopEvent.shortDescription}
                           shopShortName={shopShortName}/>
                );
            }
        }
    )
}
export default Events;