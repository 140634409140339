import {connect} from "react-redux";
import {injectAppReducer} from "@/redux/store";
import {getReducerByContext} from "@/redux/reducers/Default/combinedReducers";

const WrapperContainer = ({children, templateVersion, context}) => {

    injectAppReducer(templateVersion, getReducerByContext(context));

    return (children);
};

const mapStateToProps = (state, ownProps) => {
    return {
        children: ownProps.children,
        context: ownProps.context,
        templateVersion: ownProps.templateVersion,
    };
};
export default connect(mapStateToProps, null)(WrapperContainer);