import {TAGGING_FETCHED} from '../actionsTypes'
import ApiCaller from "@/services/ApiCaller/apiCallerDefault";

export const getTrackingData = (identify) => {
    return function (dispatch) {
        ApiCaller.get("/Tagging?identifier=" + identify)
            .then(response => {
                dispatch({
                    type: TAGGING_FETCHED,
                    payload: {
                        headerTrackingFlow: response.data.headerTrackingFlow,
                        footerTrackingFlow: response.data.footerTrackingFlow,
                    }
                });
                appData.actionTrigger.dispatch({
                    trigger: "JsDeferToJavaScript"
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}
