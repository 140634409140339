import React from "react";
import Utils from "@/utils/Default/Utils";

const Blog = ({blogs}) => {

    const addTriggerBlog = (link,title) => {
        appData.actionTrigger.dispatch({
            trigger: "clickBlogBloc",
            payload: {
                url: link,
                title: title,
            }
        });
    }
    return (
        <section className="hp__blog">
            <a href="https://blog.but.fr/" target="_blank">
                <h2>
                    <span>Le Blog</span> Découvrez nos astuces pour encore plus d'inspiration
                </h2>
            </a>
            <div>
                <div className="owl-carousel">
                    {
                        blogs?.map((blog, index) => {
                                return (
                                    <a key={index} className="slide"
                                       onClick={() => addTriggerBlog(blog.url, blog.title)}
                                       href={blog.url + '?xtatc=' + blog.title.replace(/ /g, '_') + '|' + Utils.getFormatedDate(blog.datePublication) + '|BLOG'}
                                       target="_blank">
                                        <img src={blog.imageJpgUrl} alt={blog.title} width={326}/>
                                        <h3>{blog.title}</h3>
                                    </a>
                                );
                            })
                    }

                </div>
            </div>
        </section>

    )
}
export default Blog;