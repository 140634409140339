import React, {useEffect, useState, memo} from "react";
import SparkowBlocks from "./SparkowBlocks";
import Catalog from "./Catalog";
import Script from "next/script";
import {SubmitNewsletter} from "@/animations/Default/newsletterPage";
import {getBuildId} from "@/utils/communUtil";

const prepareSparkowBlocksPart = (sparkowBlocks) => {
    let firstSparkowBlockspart = [];
    let secondSparkowBlockspart = [];
    let thirdSparkowBlockspart = [];
    let butBrandsBlock = []
    let alertBlock = []
    let target2sell = []
    let blogBlock = []
    const specialBlocks = ['Desktop_HP_Alerte', 'Desktop_HP_Marques_But', 'Desktop_HP_Titre_Marques_But', 'Desktop_HP_MediaContent', 'Desktop_HP_Service', 'Desktop_HP_TopDuMoment_T2S'];
    let isT2S = false;
    Object.keys(sparkowBlocks).map((key, index) => {
        if (specialBlocks.indexOf(key) === -1) {
            if (key === "Desktop_HP_T2S" || key === "Desktop_HP_Criteo") {
                target2sell[key] = sparkowBlocks[key];
                isT2S = true;
            } else if (isT2S) {
                secondSparkowBlockspart[key] = sparkowBlocks[key];
            } else {
                firstSparkowBlockspart[key] = sparkowBlocks[key];
                if (key == 'Desktop_HP_Template[*]_Slider') {
                    firstSparkowBlockspart['Desktop_HP_TopDuMoment_T2S'] = sparkowBlocks['Desktop_HP_TopDuMoment_T2S'];
                }
            }
        }
    })
    alertBlock['Desktop_HP_Alerte'] = sparkowBlocks['Desktop_HP_Alerte']
    blogBlock['Desktop_HP_Service'] = sparkowBlocks['Desktop_HP_Service'];
    thirdSparkowBlockspart['Desktop_HP_Titre_Marques_But'] = sparkowBlocks['Desktop_HP_Titre_Marques_But'];
    thirdSparkowBlockspart['Desktop_HP_Marques_But'] = sparkowBlocks['Desktop_HP_Marques_But'];
    thirdSparkowBlockspart['Desktop_HP_MediaContent'] = sparkowBlocks['Desktop_HP_MediaContent'];
    return {
        'firstSparkowBlockspart': firstSparkowBlockspart,
        'secondSparkowBlockspart': secondSparkowBlockspart,
        'thirdSparkowBlockspart': thirdSparkowBlockspart,
        'target2sell': target2sell,
        'butBrandsBlock': butBrandsBlock,
        'alertBlock': alertBlock,
        'blogBlock': blogBlock
    }
}

const HomeZones = memo(({sparkowBlocks, blogCollection, catalogList , mediaContentList,blogs}) => {

    const [hasBeenScroll, setHasBeenScroll] = useState(false);
    useEffect(() => {
        SubmitNewsletter();
        function handleScroll() {
            if (!hasBeenScroll && window.pageYOffset > 0) {
                setHasBeenScroll(true);
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasBeenScroll]);
    let sparkowBlockspart = prepareSparkowBlocksPart(sparkowBlocks)
    return (
        <>
            {<Script src={"/assets/js/Default/targetToSell.min.js?v="+getBuildId()} strategy="afterInteractive"/>}
            <SparkowBlocks sparkowBlocks={sparkowBlockspart.firstSparkowBlockspart} blogs={blogs} mediaContentList = {mediaContentList}/>
            <section
                     dangerouslySetInnerHTML={
                         {
                             __html: sparkowBlockspart.target2sell['Desktop_HP_T2S'].content
                         }
                     }
            />
            {hasBeenScroll && <SparkowBlocks blogs={blogs} sparkowBlocks={sparkowBlockspart.secondSparkowBlockspart}/>}
            <SparkowBlocks sparkowBlocks={sparkowBlockspart.blogBlock} blogs={blogs}/>
            {hasBeenScroll &&
                <>
                    {/*// TODO A remettre pour l'affichage de la partie BLOG dans HP Desktop*/}
                    {/*<Blog blogCollection={blogCollection}/>*/}
                    <SparkowBlocks sparkowBlocks={sparkowBlockspart.thirdSparkowBlockspart}
                                   mediaContentList={mediaContentList}
                                   blogs={blogs}/>
                    <Catalog catalogList={catalogList}/>
                    <SparkowBlocks sparkowBlocks={sparkowBlockspart.alertBlock}
                                   blogs={blogs}/>
                </>
            }
        </>
    )
})

export default HomeZones;