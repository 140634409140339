import React from "react";

const Catalog = ({catalogList}) => {

    let leafletList = catalogList.filter(item => item.isLeaflet === 1)
    let guidList = catalogList.filter(item => item.isLeaflet !== 1)
    return (
        <section className="zone hp__depliant">
            {
                leafletList.length > 0 && <div className="zone_catalogue">
                    <div>
                        <p className="title">
                            En ce moment
                        </p>
                        {
                            leafletList.map((item, index) => {
                                return (
                                    <a key={index} className='leafletCatalog'
                                       href={item.url + '?xtatc=' + item.label.replace(/ /g, '_') + '|' + item.startDate + '|DEPLIANT'}
                                       target="_blank"
                                       title={item.label}>
                                        <div>
                                            <img alt={item.label}
                                                 src={item.imageUrl}/>
                                        </div>
                                        <div>
                                            <h4>{item.label}</h4>
                                            <p>
                                                Jusqu'au {item.endDate}
                                            </p>
                                        </div>
                                    </a>
                                )
                            })
                        }
                    </div>
                    {
                        guidList.length === 0 && <a className="btn landingCatalogButton"
                                                    href="https://www.but.fr/actualites/les-catalogues-but?xtatc=Catalogue|2021-11-02|HP_DEP"
                                                    target="_blank">Voir tous nos catalogues</a>
                    }
                </div>
            }
            {
                guidList.length > 0 && <div className="zone_guide">
                    <div>
                        <p className="title">
                            Guides
                        </p>
                        {
                            guidList.map((item, index) => {
                                return (
                                    <a className='guidCatalog'
                                       key={index}
                                       href={item.url + '?xtatc=' + item.label.replace(/ /g, '_') + '|' + item.startDate + '|GUIDE'}
                                       target="_blank"
                                       title={item.label}>
                                        <div>
                                            <img alt={item.label} style={{width: '280px', height: '210px'}}
                                                 src={item.imageUrl}/>
                                        </div>
                                        <div>
                                            <h4>{item.label}</h4>
                                            <p>
                                                Jusqu'au {item.endDate}
                                            </p>
                                        </div>
                                    </a>
                                )
                            })
                        }
                    </div>
                    <a className="btn landingCatalogButton"
                       href="https://www.but.fr/actualites/les-catalogues-but"
                       target="_blank">Voir tous nos catalogues</a>
                </div>
            }

        </section>
    )
}
export default Catalog;