import React from 'react'
import BeddingMarquees from "./EventsBanners/BeddingMarquees";
import Liquidation from "./EventsBanners/Liquidation";
import GenericEvent from "./EventsBanners/GenericEvent";
import Sales from "./EventsBanners/Sales";
import Utils from "@/utils/Default/Utils";

const renderEvent = (label, typeId, displayText, displayingInfos) => {
    switch (typeId) {
        case '1':
            return <Sales displayingInfos={displayingInfos}/>;
        case '2':
            return <BeddingMarquees label={label} displayingInfos={displayingInfos}/>;
        case '8':
        case '9':
        case '10':
        case '11':
        case '12':
        case '13':
        case '14':
        case '15':
            return <Liquidation label={label} displayingInfos={displayingInfos}
                                displayText={displayText}/>;
        default:
            return <GenericEvent label={label} displayingInfos={displayingInfos}
                                 displayText={displayText}/>;
    }
}

const handleLocalCommercialAnimation = (shopShortName, displayText, label) => {
    let eventLabel = displayText ? displayText : label
    appData.actionTrigger.dispatch({
        trigger: "clickLocalCommercialAnimation",
        payload: {
            eventLabel: eventLabel.replace(/ /g, '_') + '_But' + shopShortName.replace(/ /g, '_'),
            shopShortName: shopShortName,
        }
    });
}

const Event = ({typeId, shopShortName, displayingInfos, shortDescription, label, displayText}) => {
    let urlDestinationPage = Utils.getMetaInfosValueByCode("urlDestinationPage", displayingInfos)
    return (

         typeId != '16' &&  <section className="com_locale"
                 onClick={() => handleLocalCommercialAnimation(shopShortName, displayText, label)}>
            {(urlDestinationPage) ?
                <a href={urlDestinationPage} title={shortDescription}>
                    <div className="storeBanners">
                        <div className="place">
                            <h2>Dans le magasin près de chez vous <strong>{shopShortName}</strong></h2>
                        </div>
                        {renderEvent(label, typeId, displayText, displayingInfos)}
                    </div>
                </a>
                :
                <div className="storeBanners">
                    <div className="place">
                        <h2>Dans le magasin près de chez vous <strong>{shopShortName}</strong></h2>
                    </div>
                    {renderEvent(label, typeId, displayText, displayingInfos)}
                </div>
            }
        </section>

    )
}
export default Event;