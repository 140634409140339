import React from 'react';
import Utils from "@/utils/Default/Utils";

const Sales = (props) => {
    let textEventDate = Utils.getMetaInfosValueByCode("textEventDate", props.displayingInfos)
    let discountPercentage = Utils.getMetaInfosValueByCode("discountPercentage", props.displayingInfos)
    return (
        <div className="animCo soldes">
            <div className="summary" style={{width: !discountPercentage ? '100%' : null}}>
                <h2 className="opName">SOLDES<br/><span>ET AUTRES BONS PLANS</span></h2>
                <p className="date">{textEventDate}</p>
                <p className="description"/>
            </div>
            {discountPercentage &&
            <div className="cta">
                <p>jusqu'à<br/>
                    <strong>-{discountPercentage}<sup>%<span>*</span></sup></strong><br/>
                    <span className="condition">*voir conditions et produits en Magasin</span></p>
            </div>
            }
        </div>
    );
}

export default Sales;