import React, {useEffect, useMemo} from 'react';
import Script from "next/script";
import {connect, useDispatch} from "react-redux";
import PropTypes from 'prop-types';
import HeadDetails from "../../Default/Layout/Head/index";
import Scripts from "../../Default/Layout/Head/Scripts";
import Header from "../Layout/Header/Header";
import BannerBloc from "../Layout/Header/BannerBloc";
import {initAppDataKeyValues} from "@/animations/Default/commonPage";
import ShopEventsContainer from "./ShopEvent/ShopEventsContainer";
import {getTrackingData} from "@/redux/reducers/Default/tracking/trackingActions";
import Footer from "../Layout/Footer/Footer";
import HomeZones from "./HomeZones";
import BrandLogos from "./BrandLogos";
import dynamic from 'next/dynamic'

const AdsenseBloc = dynamic(
    () => import('../Common/AdsenseBloc'),
    {ssr: false}
)
import MenuContainer from "../Common/Menu/MenuContainer";
import SeoContent from "../Layout/Footer/SeoContent";
import HomeDirectory from "../Layout/Footer/HomeDirectory";
import {HOME_PAGE} from "@/utils/constants";
import IntersectionObserverSSR from "../Layout/Resource/IntersectionObserverSSR";
import {getDefaultMenu} from "@/redux/reducers/Default/header/headerActions";
import {initTargetToSell} from "@/animations/Default/productListPage";
import {getBuildId} from "@/utils/communUtil";
import Utils from "@/utils/Default/Utils";

const DefaultHomePage = ({
                             menuItems, aboveMenuItems, mvcUrl, getTrackingData, adsenseStatus, sparkowBlocks, blogs,
                             headerTrackingScopeData, footerTrackingScopeData,
                             isDQENormalisationActive, navigationMetaInfosConfigs, octipasPayload, trackingUrlFapi, showBanners, bannersBlocList,
                             shopCircleVersion, homePageFond, blogCollection, catalogList, footerTrackingFlow, logoVersion, adaptiveLoadingResources, mediaContentList , deviceType,
                             userAgent, group, userId, hasCountDown,
                             olapicConfig,
                             ABTastyCookieIsActive,
                             isNavigationBySeller
                         }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDefaultMenu(deviceType,userAgent));
        const t2SellParams = {
            wordingVersion: 'Default',
            userGroup: group,
            webUserId: userId
        };
        initTargetToSell('homePage', t2SellParams);
        olapicConfig["check"] = Utils.checkCookies(3);
        initAppDataKeyValues(navigationMetaInfosConfigs, octipasPayload, trackingUrlFapi, 'homePage', mvcUrl, isDQENormalisationActive, shopCircleVersion, logoVersion, olapicConfig)
        getTrackingData(mvcUrl)
        if(isNavigationBySeller){
            Utils.setCookieValue('isNavigationBySeller', isNavigationBySeller);
        }
    }, [])

    // I have utilized useMemo here because the elements are passed as props, which means they are treated as objects. Since they are static HTML elements, employing useMemo is the most appropriate approach.
    const memoizedFooterChildren = useMemo(() => <><SeoContent/><HomeDirectory/></>, []);

    const memoizedStyleForAdsense = useMemo(() => ({
        "display": 'block',
        "margin": 'auto',
        "width": '728px',
        "height": '90px',
        "marginBottom": '20px'
    }), [])

    // Il ne suffit pas d'ajouter memo dans le composant HeadDetails, car il utilise children. Ce dernier est considéré comme un objet qui change à chaque fois. Par conséquent, il est nécessaire de mémoriser le link.
    const memoizedLinks = useMemo(() => <link rel="stylesheet" href={"/assets/css/default/home.css?v=" + getBuildId()}/>, [])

    // de meme ici : le composant Scripts utilise children
    const memoizedScripts = useMemo(() =>
            <>
                <Script src="/assets/js/imageMapResizer.min.js" strategy="beforeInteractive"/>
                <Script src="/assets/js/owl.carousel.min.js" strategy="beforeInteractive"/>
                <Script src={"/assets/js/Default/hp.js?v=" + getBuildId()}/>
                <Script type="text/tc_privacy" data-category="3"
                        src={"/assets/js/Default/octipasMerchLibraryConfig.js?v=" + getBuildId()}/>
            </>
        , [])
    return (
        <>
            <HeadDetails
                title="Magasins BUT : achat meubles (canapé, lit, matelas, table, salon et bureau), achat électroménager, Tv et Hi-Fi - le design pas cher."
                description="Retrouvez tout l'équipement de la maison dans vos magasins BUT : achat meubles ( canapé, lit, matelas, table, salon et bureau ), électroménager, décoration, Tv et Hi-Fi, multimedia."
                keywords="BUT, meubles, design, pas cher, canapé, fauteuil relax, banquette, clic clac, bz, convertible, lit, matelas, literie, table, bureau, salon, électroménager, mobilier, décoration, lave-linge, lave-vaisselle, sèche-linge, réfrigérateur, congélateur, Tv, LCD, Hi-Fi"
                canonical="https://www.but.fr">
                {memoizedLinks}
            </HeadDetails>
            <Scripts>
                {memoizedScripts}
            </Scripts>
            <header id="main-header" className={"main-header"}>
                <div id="main-header__infobar" className="main-header__infobar owl-carousel owl-loaded">
                    <BannerBloc showBanners={showBanners} bannersBlocList={bannersBlocList}
                                hasCountDown={hasCountDown}/>
                </div>
                <div id="header-tools-content">
                    <Header currentStep={HOME_PAGE} logoVersion={logoVersion} shopCircleVersion={shopCircleVersion}
                            headerTrackingScopeData={headerTrackingScopeData} ABTastyCookieIsActive={ABTastyCookieIsActive}/>
                </div>
                <MenuContainer menuItems={menuItems} aboveMenuItems={aboveMenuItems} currentPage={"homePage"}/>
            </header>
            {homePageFond && <div dangerouslySetInnerHTML={{__html: homePageFond}}/>}
            <div id="hp" className="hp" role="main">
                <div className="frz_nocache">
                    <ShopEventsContainer/>
                </div>
                <HomeZones sparkowBlocks={sparkowBlocks} blogs={blogs} blogCollection={blogCollection} catalogList={catalogList} mediaContentList={mediaContentList}/>
                <IntersectionObserverSSR>
                    <BrandLogos/>
                </IntersectionObserverSSR>
                {adsenseStatus &&
                    <div align="center">
                        <AdsenseBloc
                            style={memoizedStyleForAdsense}
                            resources={adaptiveLoadingResources}
                            dataAdClient="ca-pub-5844672657887373" dataAdSlot="5925553845"/>
                    </div>

                }
            </div>
            <footer id="container_footer">
                <div id="footer">
                    <Footer
                        footerTrackingFlow={footerTrackingFlow}
                        footerTrackingScopeData={footerTrackingScopeData}
                    >
                        {memoizedFooterChildren}
                    </Footer>
                </div>
            </footer>
        </>)
};
DefaultHomePage.propTypes =
    {
        mvcUrl: PropTypes.string,
        sparkowBlocks: PropTypes.object,
        blogs : PropTypes.object
    };

DefaultHomePage.defaultProps =
    {};

const mapStateToProps = (state, ownProps) => {
    return {
        footerTrackingFlow: state.tracking.footerTrackingFlow,
        menuItems: (!state.menu.isDefaultMenu) ? ownProps.menuItems : state.menu.menuItems.values,
        userId: state.header.webUserId,
        group: state.header.userData?.group
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTrackingData: (mvcUrl) => dispatch(getTrackingData(mvcUrl))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DefaultHomePage);
