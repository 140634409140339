import React from "react";
import PropTypes from "prop-types";

const SlidersBlock = ({sliderBlock}) => {
    if (sliderBlock.type === 'Template1') {
        let htmlContent = sliderBlock.zones.length > 0 ? sliderBlock.zones[0]?.content : '';
        return (
            <section
                className="hp__template1-slider1 sliderZone hp_slider slider owl-carousel slider_onezone leftToRight"
                dangerouslySetInnerHTML={{
                __html: htmlContent
            }}/>
        )
    } else if (sliderBlock.type === 'Template2') {
        return (
            <section className="hp_slider_2zones slider_multizones">

                {
                    sliderBlock.zones.map((item, index) => {
                        return (
                            <div key={index}
                                className={item.name === "Desktop_HP_Template2_Slider1" ?
                                    "hp__template2-slider2 slider sliderZone owl-carousel leftToRight" :
                                    "hp__template3-slider3 slider sliderZone owl-carousel leftToRight"}
                                dangerouslySetInnerHTML={{
                                    __html: (item.content ?
                                        item.content
                                        : '')
                                }}>

                            </div>
                        )
                    })
                }

            </section>
        )
    } else if (sliderBlock.type === 'Template3') {
        let firstZone = sliderBlock.zones.filter(item => item.name === 'Desktop_HP_Template3_Slider1');
        let otherZones = sliderBlock.zones.filter(item => item.name !== 'Desktop_HP_Template3_Slider1');
        return (
            <section className="hp_slider_3zones slider_multizones">
                <div className="hp__template4-slider4 sliderZone slider slider_1 owl-carousel leftToRight "
                     dangerouslySetInnerHTML={{
                         __html: (firstZone[0].content ?
                             firstZone[0].content
                             : '')
                     }}
                />
                <div>
                    {
                        otherZones.map((item, index) => {
                            return (
                                <div key={index}
                                    className={item.name === "Desktop_HP_Template3_Slider2" ? "hp__template5-slider5 sliderZone slider slider_2 owl-carousel leftToRight" :
                                        "hp__template6-slider6 sliderZone slider slider_2 owl-carousel leftToRight"}
                                    dangerouslySetInnerHTML={{
                                        __html: (item.content ?
                                            item.content
                                            : '')
                                    }}
                                />
                            )
                        })
                    }
                </div>
            </section>
        )
    }
    return null;
}

SlidersBlock.propTypes = {
    sparkowBlocks: PropTypes.object
};

SlidersBlock.defaultProps = {};

export default SlidersBlock;