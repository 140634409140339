import $ from "jquery";

function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

export const SubmitNewsletter = () => {
    var field = $("#newsletter .field");
    $(".formNewsletter").submit(function (e) {
        e.preventDefault();
        var valueEmail = $("#newsletter .field").val();
        if (valueEmail === '') {
            field.removeClass("hideAlert invalid")
            field.addClass("empty")
        } else if ( isEmail(valueEmail) == false) {
            field.removeClass("hideAlert empty")
            field.addClass("invalid")
        } else {
            field.removeClass("hideAlert empty invalid")
            localStorage.setItem('newsletterEmail', valueEmail);
            appData.actionTrigger.dispatch({
                trigger: "submitNewsletterForm",
                resolveCallback: function () {
                    window.location.href = "InscriptionNewsletterBUT";
                }
            });
        }
    });
}
