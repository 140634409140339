import React from 'react';
import Utils from "@/utils/Default/Utils";

const BeddingMarquees = (props) => {
    let textEventDate = Utils.getMetaInfosValueByCode("textEventDate", props.displayingInfos)
    let discountPercentage = Utils.getMetaInfosValueByCode("discountPercentage", props.displayingInfos)
    let displayText = Utils.getMetaInfosValueByCode("textDisplayingFront", props.displayingInfos).length > 0 ? Utils.getMetaInfosValueByCode("textDisplayingFront", props.displayingInfos) : Utils.getMetaInfosValueByCode("textMobile", props.displayingInfos)
    let textColor = Utils.getMetaInfosValueByCode("textColor", props.displayingInfos).length > 0 ? Utils.getMetaInfosValueByCode("textColor", props.displayingInfos) : Utils.getMetaInfosValueByCode("colorMobile", props.displayingInfos)
    return (
        <div className="animCo marqueeBedding">
            <div className="summary" style={{width: !discountPercentage ? '100%' : null}}>
                <h2 className="opName">{displayText[0]}
                    <span className="big" style={{color: textColor}}> {displayText[1] && displayText[1]}</span>
                </h2>
                <p className="date">{textEventDate}</p>
                <p className="description"/>
            </div>
            {discountPercentage &&
            <div className="cta">
                <p>jusqu'à<br/>
                    <strong>-{discountPercentage}<sup>%<span>*</span></sup></strong><br/>
                    <span className="condition">*voir conditions et produits en Magasin</span></p>
            </div>
            }
        </div>
    );
}

export default BeddingMarquees;