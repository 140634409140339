import React, {memo} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import Events from "./Events";

const ShopEvents = memo(() => {
    const headerStore = useSelector(store => store.header.shop.shopInfos)
    const shopEventList = useSelector(store => store.header.shopEventList, shallowEqual)
    const shopShortName = headerStore ? headerStore.shortName : ''
    return (
        <>
            {shopEventList && <Events shopShortName={shopShortName}
                                      shopEventList={shopEventList} context={'animCoHomePage'}/>}
        </>
    )
})
export default ShopEvents;

