import React, {useState, useEffect, memo} from 'react';
import {useInView} from "react-intersection-observer";

const IntersectionObserverSSR = memo((props) => {
    const [hasBeenSeen, setHasBeenSeen] = useState(false);
    let [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
        fallbackInView: true,
        rootMargin: (!!props.rootMargin) ? props.rootMargin : '',
    });
    useEffect(() => {
        if (inView && !hasBeenSeen) {
            setHasBeenSeen(true);
        }
    }, [inView, hasBeenSeen]);
    return (
        <div ref={ref}>
            {(hasBeenSeen || typeof window === 'undefined') && props.children}
        </div>
    );
});
export default IntersectionObserverSSR;