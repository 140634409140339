import React from 'react';

export const SeoContent = ( ) => {

    return (
        <div id="contenu_seo">
            <h1>Achat Meubles, Electroménager, TV Son et High Tech, Matelas et sommiers, Cuisine équipée et Décoration</h1>
            <p>
                Commandez vos meubles, votre cuisine, votre matelas ou votre canapé pas cher sur But.fr votre magasin spécialiste de l'équipement et de la décoration de maison et appartement. Le site des magasins de meuble et électroménager But
                propose des canapés, lits pas cher, meubles de salon et bureau, meubles de cuisine, de chambre à coucher, de salle de bain et autres idées d’objets de déco aux meilleurs prix tout au long de l'année durant les soldes ou encore à
                Noël. Vous retrouverez également un large choix de canapés, clic-clac, banquettes, canapé lit, chaises, tables et matelas. Vous trouverez chez But tous les styles de déco pour vos meubles : du meuble design et moderne au
                traditionnel pour revoir l’aménagement de vos pièces ou préparer un emménagement. Une petite hésitation sur l'achat de matériel électroménager, hi-fi ou TV ? Référez-vous aux avis des clients But, aux vidéos de présentation des
                produits, à la comparaison de produits pour trouver le produit le moins cher ou le plus adapté à vos besoins ou à nos guides d’aide au choix.
            </p>
            <p>
                Profitez également de tous nos services pour acheter en toute sérénité une cuisine équipée pas cher, du mobilier déco ou du matériel TV et son : les packs simplicité (pour étendre vos garanties en cas de panne), les options de
                livraison (retrait dans en magasin, livraison colis, prêt de camion, transporteur...) et les modes de paiement sécurisés (carte de crédit, cartes de crédit VISA AURORE BUT et BUT AURORE). Enfin, pour poser toutes vos questions
                (garantie, SAV, mode d’emploi, etc.) et partager votre avis, rendez-vous sur votre espace client ou contactez notre service consommateur.
            </p>
            <p>
                Découvrez notre espace bons plans et réduction pour plus de petits prix discount et profiter de depromo exceptionnelles durant les soldes ou les ventes privées. Pour tous vos cadeaux de Noël et vos achats durant les soldes,
                rendez-vous sur But.fr pour retrouver toutes nos ventes flash, produits exclusifs de dernière minute, destockage et autres bonnes affaires au meilleur prix. Abonnez-vous à notre newsletter pour être informé de nos promotions
                toute l'année et à Noël pour trouver de bonnes idées cadeaux.
            </p>
        </div>
    )
};
export default SeoContent;