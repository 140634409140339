import React from "react";
import PropTypes from "prop-types";
import SlidersBlock from "./SlidersBlock";
import dynamic from 'next/dynamic'
import Blog from "./Blog";

const MediaContent = dynamic(
    () => import('./MediaContent'),
    {ssr: false}
)
const SparkowBlocks = ({sparkowBlocks, mediaContentList, blogs}) => {
    return Object.keys(sparkowBlocks).map((key, index) => {
        if (key === 'Desktop_HP_Template[*]_Slider') {
            return (
                <SlidersBlock key={index} sliderBlock={sparkowBlocks[key].sliders}/>
            )
        }
        if (key === 'Desktop_HP_TopDuMoment_T2S') {
            return (
                <section>
                    <section className="zone t2s specialOp">
                        <div className="target2sell" id="PPNA1QL127P68J-1008-1585" />
                    </section>
                </section>
            );
        }
        if (key === 'Desktop_HP_MediaContent') {
            return (
                <MediaContent key={index} sparkowBlock={sparkowBlocks[key].content ? sparkowBlocks[key].content : ''}
                              mediaContentList={mediaContentList}/>
            )
        }
        else {
            if (key == 'Desktop_HP_Boutique' && sparkowBlocks[key].content) {
                return (<React.Fragment key={index}>
                        <section key={index}
                                 style={['Desktop_HP_Marques_But'].includes(key) ? {padding: 0} : {}}
                                 dangerouslySetInnerHTML={{
                                     __html: sparkowBlocks[key].content
                                 }}
                        />
                    </React.Fragment>);
            } else if (key == 'Desktop_HP_Service') {
                return (<Blog blogs={blogs}/>)
            } else if (sparkowBlocks[key]?.content) {
                return (<section key={index} style={['Desktop_HP_Marques_But'].includes(key) ? {padding: 0} : {}}
                                 dangerouslySetInnerHTML={{
                                     __html: sparkowBlocks[key].content
                                 }}
                />)
            }
            return null
        }
    })
}

SparkowBlocks.propTypes = {
    sparkowBlocks: PropTypes.object,
    blogs: PropTypes.object
};

SparkowBlocks.defaultProps = {};

export default SparkowBlocks;