import React, {Fragment} from 'react';
import Utils from "@/utils/Default/Utils";

const getAnimCoClassName = (labelName) => {
    switch (labelName) {
        case 'Destockage':
            return 'animCo destockage';
        case 'Ouvertures':
            return 'animCo opening';
        case 'Nocturne':
            return 'animCo night';
        default:
            return 'animCo'

    }
}

const GenericEvent = (props) => {
    let textEventDate = Utils.getMetaInfosValueByCode("textEventDate", props.displayingInfos)
    let discountPercentage = Utils.getMetaInfosValueByCode("discountPercentage", props.displayingInfos)
    let eventSlogan = Utils.getMetaInfosValueByCode("eventSlogan", props.displayingInfos)
    let eventTime = Utils.getMetaInfosValueByCode("eventTime", props.displayingInfos)
    let textColor = ""
    let textDisplayingInFront = ""
    if(props.displayText !== ""){
        textDisplayingInFront = Utils.getMetaInfosValueByCode("textDisplayingFront", props.displayingInfos);
        textColor = Utils.getMetaInfosValueByCode("textColor", props.displayingInfos);
    }else{
        textDisplayingInFront = Utils.getMetaInfosValueByCode("textMobile", props.displayingInfos);
        textColor = Utils.getMetaInfosValueByCode("colorMobile", props.displayingInfos);
    }
    let label = textDisplayingInFront !== "" ? textDisplayingInFront : props.label.split(' ');
    return (
        <div className={getAnimCoClassName(label[0])}>
            <div className="summary" style={{width: !discountPercentage ? '100%' : null}}>
                {label[0] === 'Destockage' || label[0] === 'Nocturne' ?
                    <h2 className="opName">SP&Eacute;CIAL{label[0] === 'Nocturne' && 'E'} <span>{label[0]}</span></h2>
                    :
                    <h2 className="opName">{label[0]}
                        <span className="big" style={{color: textColor}}>{label[1]}</span>
                    </h2>
                }
                <p className="date">{textEventDate} <span>{eventTime}</span></p>
                <p className="description event">{eventSlogan}</p>
            </div>
            {discountPercentage ?
                <div className="cta">
                    <p>jusqu'à<br/>
                        <strong>-{discountPercentage}<sup>%<span>*</span></sup></strong><br/>
                        <span className="condition">*voir conditions et produits en Magasin</span></p>
                </div>
                :
                <Fragment>
                    {label[0] === 'Atelier' &&
                    <div className="cta">
                        <strong className="linkBox">Je m'inscris</strong>
                    </div>
                    }
                </Fragment>
            }
        </div>
    );
}

export default GenericEvent;