import React , {useEffect} from "react";
import dynamic from 'next/dynamic'

const WhirlpoolSvg = dynamic(
    () => import('./SvgBrands/WhirlpoolSvg'),
    {ssr: false, loading: () => "Whirlpool"}
)
const IndesitSvg = dynamic(
    () => import('./SvgBrands/IndesitSvg'),
    {ssr: false, loading: () => "Indesit"}
)
const BekoSvg = dynamic(
    () => import('./SvgBrands/BekoSvg'),
    {ssr: false, loading: () => "Beko"}
)

const MoulinexSvg = dynamic(
    () => import('./SvgBrands/MoulinexSvg'),
    {ssr: false, loading: () => "Moulinex"}
)

const TefalSvg = dynamic(
    () => import('./SvgBrands/TefalSvg'),
    {ssr: false, loading: () => "Tefal"}
)
const RowentaSvg = dynamic(
    () => import('./SvgBrands/RowentaSvg'),
    {ssr: false, loading: () => "Rowenta"}
)

const CalorSvg = dynamic(
    () => import('./SvgBrands/CalorSvg'),
    {ssr: false, loading: () => "Calor"}
)
const SamsungSvg = dynamic(
    () => import('./SvgBrands/SamsungSvg'),
    {ssr: false, loading: () => "Samsung"}
)

const DysonSvg = dynamic(
    () => import('./SvgBrands/DysonSvg'),
    {ssr: false, loading: () => "Dyson"}
)

const ElectroluxSvg = dynamic(
    () => import('./SvgBrands/ElectroluxSvg'),
    {ssr: false, loading: () => "Electrolux"}
)

const PhilipsSvg = dynamic(
    () => import('./SvgBrands/PhilipsSvg'),
    {ssr: false, loading: () => "Philips"}
)
const LgSvg = dynamic(
    () => import('./SvgBrands/LgSvg'),
    {ssr: false, loading: () => "Lg"}
)
const HaierSvg = dynamic(
    () => import('./SvgBrands/HaierSvg'),
    {ssr: false, loading: () => "Haier"}
)
const BrandtSvg = dynamic(
    () => import('./SvgBrands/BrandtSvg'),
    {ssr: false, loading: () => "Brandt"}
)
const CandySvg = dynamic(
    () => import('./SvgBrands/CandySvg'),
    {ssr: false, loading: () => "Candy"}
)
const BoschSvg = dynamic(
    () => import('./SvgBrands/BoschSvg'),
    {ssr: false, loading: () => "Bosch"}
)

const IRobotSvg = dynamic(
    () => import('./SvgBrands/IRobotSvg'),
    {ssr: false, loading: () => "IRobot"}
)

const HotpointSvg = dynamic(
    () => import('./SvgBrands/HotpointSvg'),
    {ssr: false, loading: () => "Hotpoint"}
)

const SmegSvg = dynamic(
    () => import('./SvgBrands/SmegSvg'),
    {ssr: false, loading: () => "Smeg"}
)

const TclSvg = dynamic(
    () => import('./SvgBrands/TclSvg'),
    {ssr: false, loading: () => "Tcl"}
)

const BrandLogos = () => {

    return (
            <section className="zone logos_marques">
                <ul>
                    <li key ={'whirlpool'} >
                        <a href={"/marques/whirlpool/index-c10436.html?xtatc=whirlpool||BrandLogos"} title="whirlpool">
                            <WhirlpoolSvg/>
                        </a>
                    </li>
                    <li key ={'indesit'}>
                        <a href="/marques/indesit/index-c10412.html?xtatc=indesit||BrandLogos" title="indesit">
                            <IndesitSvg/>
                        </a>
                    </li>
                    <li key ={'beko'}>
                        <a href="/marques/beko/index-c10396.html?xtatc=beko||BrandLogos" title="beko">
                            <BekoSvg/>
                        </a>
                    </li>
                    <li key ={'tefal'} className="Tefal">
                        <a href="/marques/tefal/index-c10433.html?xtatc=Tefal||BrandLogos" title="Tefal">
                            <TefalSvg/>
                        </a>
                    </li>
                    <li key ={'moulinex'}>
                        <a href="/marques/moulinex/index-c10419.html?xtatc=Moulinex||BrandLogos" title="Moulinex">
                            <MoulinexSvg />
                        </a>
                    </li>

                    <li key ={'rowenta'}><a href="/marques/rowenta/index-c10423.html?xtatc=rowenta||BrandLogos" title="rowenta">
                        <RowentaSvg/>
                    </a></li>
                    <li key ={'calor'} className="Calor">
                        <a href="/marques/calor/index-c10400.html?xtatc=calor||BrandLogos" title="calor">
                            <CalorSvg />
                        </a>
                    </li>
                    <li key ={'samsung'}><a href="/marques/samsung/index-c10424.html?xtatc=samsung||BrandLogos" title="samsung">
                        <SamsungSvg />
                    </a></li>
                    <li key ={'dyson'} className="Dyson"><a href="/marques/dyson/index-c10406.html?xtatc=dyson||BrandLogos" title="dyson">
                        <DysonSvg />
                    </a></li>
                    <li key ={'electrolux'}><a href="/marques/electrolux/index-c10407.html?xtatc=electrolux||BrandLogos" title="electrolux">
                        <ElectroluxSvg />
                    </a></li>
                    <li key ={'philips'} className="Philips"><a href="/marques/philips/index-c10421.html?xtatc=Philips||BrandLogos" title="Philips">
                        <PhilipsSvg />
                    </a></li>
                    <li key ={'lg'}><a href="/marques/lg/index-c10417.html?xtatc=lg||BrandLogos" title="lg">
                        <LgSvg />
                    </a></li>
                    <li  key ={'haier'} className="Haier"><a href="/marques/haier/index-c10410.html?xtatc=haier||BrandLogos" title="haier">
                        <HaierSvg />
                    </a></li>
                    <li key ={'brandt'} ><a href="/marques/brandt/index-c10398.html?xtatc=brandt||BrandLogos" title="brandt">
                        <BrandtSvg />
                    </a></li>
                    <li key ={'candy'} ><a href="/marques/candy/index-c10401.html?xtatc=candy||BrandLogos" title="candy">
                        <CandySvg />
                    </a></li>
                    <li key ={'bosch'}  className="Bosch"><a href="/marques/bosch/index-c10397.html?xtatc=Bosch||BrandLogos" title="Bosch">
                        <BoschSvg/>
                    </a></li>
                    <li key ={'irobot'} className="iRobot"><a href="/marques/irobot/index-c240905.html?xtatc=iRobot||BrandLogos" title="iRobot">
                        <IRobotSvg />
                    </a></li>
                    <li key ={'hotpoint'} ><a href="/marques/hotpoint/index-c10411.html?xtatc=Hotpoint||BrandLogos" title="Hotpoint">
                        <HotpointSvg/>
                    </a></li>
                    <li key ={'smeg'}><a href="/marques/smeg/index-c10430.html?xtatc=Smeg||BrandLogos" title="Smeg">
                        <SmegSvg />
                    </a></li>
                    <li key ={'tcl'} ><a href="/marques/tcl/index-c10432.html?xtatc=TCL||BrandLogos" title="TCL">
                        <TclSvg />
                    </a></li>
                </ul>
            </section>
    );
}

export default BrandLogos;