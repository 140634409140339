import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {getHomePageSSRData} from "@/services/homeServices";
import {DEFAULT_TEMPLATE_VERSION} from "@/utils/constants";
import DefaultHomePage from "@/components/Default/Home/homePage";
import DefaultWrapperContainer from "@/components/Default/WrapperContainer";
import cookie from "cookie"
import {getCustomMenu} from "@/services/menuServices";
import {setAxiosServerConfig} from "@/services/ApiCaller/apiCallerSSR";

const Home = ({
                  menuItems,aboveMenuItems, mvcUrl, adsenseStatus, sparkowBlocks, blogs,
                  googleMapsKey, headerTrackingScopeData, footerTrackingScopeData,
                  isDQENormalisationActive, navigationMetaInfosConfigs, octipasPayload, trackingUrlFapi, wordingVersion,
                  showBanners, bannersBlocList, shopCircleVersion, homePageFond, eventListData,
                  blogCollection, catalogList, logoVersion, adaptiveLoadingResources,
                  deviceType,
                  userAgent,
                  mediaContentList,
                  hasCountDown,
                  olapicConfig,
                  ABTastyCookieIsActive,
                  isNavigationBySeller
              }) => {
      const context =  'standard';
        return (
            <DefaultWrapperContainer context={context} templateVersion={wordingVersion}>
                <DefaultHomePage {...{
                    context,
                    menuItems,
                    aboveMenuItems,
                    mvcUrl,
                    adsenseStatus,
                    sparkowBlocks,
                    blogs,
                    googleMapsKey,
                    headerTrackingScopeData,
                    footerTrackingScopeData,
                    isDQENormalisationActive,
                    navigationMetaInfosConfigs,
                    octipasPayload,
                    trackingUrlFapi,
                    wordingVersion,
                    shopCircleVersion,
                    eventListData,
                    blogCollection,
                    catalogList,
                    showBanners,
                    bannersBlocList,
                    homePageFond,
                    logoVersion,
                    adaptiveLoadingResources,
                    deviceType,
                    userAgent,
                    mediaContentList,
                    hasCountDown,
                    olapicConfig,
                    ABTastyCookieIsActive,
                    isNavigationBySeller
                }}/>
            </DefaultWrapperContainer>
        )
};

export async function getServerSideProps({req, res}) {
    setAxiosServerConfig(res)
    const deviceType = DEFAULT_TEMPLATE_VERSION;
    const userAgent = req.headers['user-agent'];
    const parsedCookies = req.headers.cookie ? cookie.parse(req.headers.cookie) : null;
    const cUserID  = (parsedCookies && parsedCookies.CUserID) ? parsedCookies.CUserID : '';
    const homePageData = await getHomePageSSRData(deviceType, userAgent, cUserID, req.url);
    const menuData = await getCustomMenu(deviceType, userAgent, null, true, cUserID, true, req.url);
    let countDownElement = homePageData.bannersBlocList?.filter((item, index) => {return item.hasCountDown === true });
    const isNavigationBySeller = req.url.includes('sellerStoreAccess');
    return {
        props: {
            menuItems: menuData.menuItems.values,
            aboveMenuItems: menuData.aboveMenuItems.values ,
            mvcUrl: homePageData.mvcUrl,
            wordingVersion: homePageData.wordingVersion,
            showBanners: homePageData.showBanners ? 'true' : 'false',
            bannersBlocList: homePageData.bannersBlocList ? homePageData.bannersBlocList : [],
            hasCountDown: countDownElement?.length ? true : false,
            eventListData: homePageData.eventListData ? homePageData.eventListData : [],
            catalogList: homePageData.catalogList ? homePageData.catalogList : [],
            blogCollection: homePageData.blogCollection,
            shopCircleVersion: homePageData.shopCircleVersion,
            sparkowBlocks: homePageData.sparkowBlocks,
            blogs: homePageData.blogs,
            googleMapsKey: homePageData.googleMapsKey,
            adsenseStatus: homePageData.adsenseStatus,
            headerTrackingScopeData: homePageData.headerTrackingScopeData,
            footerTrackingScopeData: homePageData.footerTrackingScopeData,
            isDQENormalisationActive: homePageData.isDQENormalisationActive,
            navigationMetaInfosConfigs: homePageData.navigationMetaInfosConfigs,
            octipasPayload: homePageData.octipasPayload,
            homePageFond: homePageData.homePageFond,
            trackingUrlFapi: homePageData.trackingUrlFapi,
            logoVersion: homePageData.logoVersion,
            deviceType: deviceType,
            userAgent: userAgent,
            mediaContentList: homePageData.mediaContentList,
            adaptiveLoadingResources : homePageData.adaptiveLoadingResources,
            olapicConfig: homePageData.olapicConfig,
            ABTastyCookieIsActive: homePageData.ABTastyCookieIsActive,
            isNavigationBySeller: isNavigationBySeller,
        }
    }
}

Home.propTypes =
    {
        context: PropTypes.string,
        mvcUrl: PropTypes.string,
        sparkowBlocks: PropTypes.object,
        adaptiveLoadingResources: PropTypes.object
    };

Home.defaultProps = {
    adaptiveLoadingResources: {
        "Ressource1": {
            "enabledLevels": "Level2,Level3",
            "ressourceName": "Olapic"
        },
        "Ressource2": {
            "enabledLevels": "Level2,Level3",
            "ressourceName": "FlixMedia"
        },
        "Ressource3": {
            "enabledLevels": "Level3",
            "ressourceName": "Adsense"
        },
        "Ressource4": {
            "enabledLevels": "Level1,Level2,Level3",
            "ressourceName": "VideoViewer"
        }
    }
};
export default connect(null, null)(Home);