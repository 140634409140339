import React from 'react';

export const HomeDirectory = () => {
    return (
        <div id="directory_home">
            <div className="directory_home_container">
                <h2 className="button_switcher">Index</h2>
                <div className="tableau active">
                    <div className="contenu">
                        <div id="mobilier" className="bloc agauche">
                            <h2><a href="/mobilier/index-a10316.html">Meuble Salon</a></h2>
                            <ul>
                                <li>
                                    <a href="/mobilier/canape-fauteuil/canape/index-c22721.html"><h3>Canapé</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/mobilier/canape-fauteuil/canape/index-c22721/NW-53-revetement~cuir">Canapé
                                        cuir</a>
                                    ,&nbsp;
                                    <a href="/mobilier/canape-fauteuil/canape/index-c22721/NW-53-revetement~tissu">Canapé
                                        tissu</a>
                                    ,&nbsp;
                                    <a href="/mobilier/canape-fauteuil/canape-d-angle/index-c11594.html">Canapé
                                        d'angle</a>
                                    ,&nbsp;
                                    <a href="/mobilier/canape-fauteuil/canape/index-c22721/NW-12086-nombre-de-places~2-places">Canapé
                                        2 places</a>
                                </li>
                                <li>
                                    <a href="/mobilier/canape-fauteuil/canape-convertible/index-c11595.html">
                                        <h3>Canapé convertible</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/mobilier/banquette/banquette-et-accessoire/index-c11587.html">Banquette</a>
                                    ,&nbsp;
                                    <a href="/mobilier/banquette/banquette-clic-clac/index-c11589.html">Clic-clac</a>
                                    ,&nbsp;
                                    <a href="/mobilier/banquette/banquette-bz/index-c11588.html">Bz</a>
                                    ,&nbsp;
                                    <a href="/mobilier/banquette/accessoire-banquette/index-c11591.html">Housse canapé
                                        et clic-clac</a>
                                </li>
                                <li>
                                    <a href="/mobilier/canape-fauteuil/fauteuil/index-c22722.html"><h3>Fauteuil</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/mobilier/canape-fauteuil/fauteuil/index-c22722/NW-6262-revetement~cuir">Fauteuil
                                        cuir</a>
                                    ,&nbsp;
                                    <a href="/mobilier/canape-fauteuil/fauteuil-relax/index-c11599.html">Fauteuil
                                        relax</a>
                                    ,&nbsp;
                                    <a href="/decoration/coussin-plaid-pouf/pouf-poire/index-c11294.html">Pouf</a>
                                </li>
                                <li>
                                    <a href="/chambre-literie/chambre-adulte/index-b11481.html"><h3>Mobilier chambre
                                        adulte</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/chambre-literie/chambre-adulte/lit/index-c11512.html">Lit</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/chambre-adulte/tete-de-lit/index-c11517.html">Tête de
                                        lit</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/chambre-adulte/armoire/index-c11519.html">Dressing,&nbsp; armoire
                                        et penderie</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/chambre-adulte/commode-chiffonnier/index-c11514.html">Commode</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/chambre-adulte/table-de-chevet/index-c11513.html">Table de
                                        chevet</a>
                                </li>
                                <li>
                                    <a href="/chambre-literie/chambre-enfant/index-b11482.html"><h3>Meubles chambre
                                        enfant</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/chambre-literie/chambre-enfant/lit-enfant/index-c11494.html">Lit enfant et
                                        gigogne</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/chambre-enfant/lit-superpose-mezzanine/index-c11496.html">Lit
                                        superposé et mezzanine</a>
                                    ,&nbsp;
                                    <a href="/puericulture/chambre-bebe/lit-bebe/index-c11408.html">Lit bébé</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/chambre-enfant/lit-enfant/index-c11494/NW-4374-type-de-lit~lit-cabane">Lit
                                        cabane</a>
                                    ,&nbsp;
                                    <a href="/mobilier/canape-fauteuil/chauffeuse/index-c11600.html">Chauffeuse</a>
                                </li>
                                <li>
                                    <a href="/mobilier/sejour-coin-repas/index-b11548.html"><h3>Meubles séjour</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/mobilier/salon/bibliotheque-et-etagere/index-c11583.html">Bibliothèque</a>
                                    ,&nbsp;
                                    <a href="/mobilier/salon/table-basse/index-c11580.html">Table basse</a>
                                    ,&nbsp;
                                    <a href="/mobilier/sejour-coin-repas/table/index-c11570.html">Table</a>
                                    ,&nbsp;
                                    <a href="/mobilier/sejour-coin-repas/chaise/index-c11573.html">Chaise</a>
                                    ,&nbsp;
                                    <a href="/mobilier/salon/table-console/index-c11585.html">Console</a>
                                    ,&nbsp;
                                    <a href="/mobilier/sejour-coin-repas/buffet/index-c11568.html">Buffet</a>
                                    ,&nbsp;
                                    <a href="/mobilier/sejour-coin-repas/vaisselier-et-vitrine/index-c11569.html">Vitrine</a>
                                    ,&nbsp;
                                    <a href="/mobilier/salon/meuble-tv/index-c11582.html">Meuble TV Hifi</a>
                                </li>
                                <li>
                                    <a href="/mobilier/bureau/index-b11550.html"><h3>Meuble bureau</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/mobilier/bureau/bureau-secretaire/index-c11552.html">Bureau</a>
                                    ,&nbsp;
                                    <a href="/mobilier/bureau/chaise-fauteuil-bureau/index-c11557.html">Chaise de
                                        bureau</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contenu">
                        <div id="electromenager" className="bloc">
                            <h2><a href="/electromenager/index-a10322.html">Electroménager</a></h2>
                            <ul>
                                <li>
                                    <a href="/electromenager/froid/refrigerateurs/index-c11184.html"><h3>Réfrigérateur
                                        et frigo</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/electromenager/froid/congelateurs/index-c11190.html">Congélateur</a>
                                    ,&nbsp;
                                    <a href="/electromenager/froid/cave-a-vin/index-c11194.html">Cave à vin</a>
                                </li>
                                <li>
                                    <a href="/electromenager/lavage/index-b11065.html"><h3>Electroménager lavage</h3>
                                    </a>
                                    &nbsp;:&nbsp;
                                    <a href="/electromenager/lavage/tous-les-lave-linge/index-c11196.html">Lave
                                        linge</a>
                                    ,&nbsp;
                                    <a href="/electromenager/lavage/seche-linge/index-c11200.html">Seche linge</a>
                                    ,&nbsp;
                                    <a href="/electromenager/lavage/tous-les-lave-vaisselle/index-c11201.html">Lave
                                        vaisselle</a>
                                </li>
                                <li>
                                    <a href="/electromenager/entretien-de-la-maison/aspirateur/index-c11153.html">
                                        <h3>Aspirateur</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/electromenager/entretien-de-la-maison/aspirateur/index-c11153/NW-4281-type-d%27aspirateur~aspirateur-sans-sac">Aspirateur
                                        sans sac</a>
                                    ,&nbsp;
                                    <a href="/electromenager/entretien-de-la-maison/aspirateur/index-c11153/NW-50-marque~dyson">Aspirateur
                                        Dyson</a>
                                    ,&nbsp;
                                    <a href="/electromenager/entretien-de-la-maison/aspirateur/index-c11153/NW-50-marque~rowenta">Rowenta</a>
                                    ,&nbsp;
                                    <a href="/electromenager/entretien-de-la-maison/aspirateur/index-c11153/NW-4281-type-d%27aspirateur~aspirateur-robot">Robot
                                        aspirateur</a>
                                    ,&nbsp;
                                    <a href="/electromenager/entretien-de-la-maison/nettoyeur-balai-vapeur/index-c11159.html">Nettoyeur
                                        vapeur</a>
                                </li>
                                <li>
                                    <a href="/electromenager/repassage-couture/index-b11069.html"><h3>Soin du linge</h3>
                                    </a>
                                    &nbsp;:&nbsp;
                                    <a href="/electromenager/repassage-couture/centrale-vapeur/index-c11148.html">Centrale
                                        vapeur</a>
                                    ,&nbsp;
                                    <a href="/electromenager/repassage-couture/fer-a-repasser/index-c11147.html">Fer à
                                        repasser</a>
                                    ,&nbsp;
                                    <a href="/electromenager/repassage-couture/table-a-repasser/index-c11150.html">Table
                                        à repasser</a>
                                </li>
                                <li>
                                    <a href="/electromenager/cuisson/index-b11067.html"><h3>Cuisson</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/electromenager/cuisson/four/index-c11167.html">Four</a>
                                    ,&nbsp;
                                    <a href="/electromenager/cuisson/micro-ondes/index-c11168.html">Four micro-onde</a>
							,&nbsp;
                                    <a href="/electromenager/cuisson/mini-four-four-posable/index-c11169.html">Mini four</a>
                                    ,&nbsp;
                                    <a href="/electromenager/cuisson/hotte/index-c11170.html">Hotte</a>
                                    ,&nbsp;
                                    <a href="/electromenager/cuisson/plaque-de-cuisson/index-c11171.html">Plaque de
                                        cuisson</a>
                                    ,&nbsp;
                                    <a href="/electromenager/cuisson/gaziniere/index-c11178.html">Gazinière</a>
                                </li>
                                <li>
                                    <a href="/electromenager/appareil-de-cuisson/index-b11072.html"><h3>Petits appareils
                                        de cuisson</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/electromenager/appareil-de-cuisson/friteuse/index-c11106.html">Friteuse et
                                        multi cuiseur</a>
                                    ,&nbsp;
                                    <a href="/electromenager/barbecue-plancha/barbecue-electrique/index-c11091.html">Barbecue
                                        électrique</a>
                                </li>
                                <li>
                                    <a href="/electromenager/petit-dejeuner-cafe/index-b11070.html"><h3>Electroménager
                                        petit déjeuner</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/electromenager/petit-dejeuner-cafe/toutes-les-cafetieres-et-machines-a-dosettes/index-c11133.html">Cafetiere</a>
                                    ,&nbsp;
                                    <a href="/electromenager/petit-dejeuner-cafe/grille-pain/index-c11144.html">Grille
                                        pain</a>
                                    ,&nbsp;
                                    <a href="/electromenager/petit-dejeuner-cafe/bouilloire/index-c11142.html">Bouilloire</a>
                                </li>
                                <li>
                                    <a href="/electromenager/robot-preparation-culinaire/index-b11071.html">
                                        <h3>Electroménager préparation culinaire</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/electromenager/petit-dejeuner-cafe/centrifugeuse-extracteur-de-jus/index-c11140.html">Centrifugeuse</a>
                                    ,&nbsp;
                                    <a href="/electromenager/robot-preparation-culinaire/robot-multifonction/index-c11117.html">Robot
                                        multifonction</a>
                                    ,&nbsp;
                                    <a href="/electromenager/robot-preparation-culinaire/blender-blender-chauffant/index-c11120.html">Blender</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contenu">
                        <div id="tv" className="bloc agauche">
                            <h2><a href="/tv-image-et-son/index-a10325.html">TV Son et High Tech</a></h2>
                            <ul>
                                <li>
                                    <a href="/tv-image-et-son/tv/televiseur/index-c10933.html"><h3>Télévision</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/tv-image-et-son/tv/televiseur/index-c10933/NW-10714-technologie-de-l%27ecran~led">TV
                                        LED</a>
                                    ,&nbsp;
                                    <a href="/tv-image-et-son/tv/televiseur/index-c10933/NW-6442-resolution-de-l%27image~ultra-hd-4k">TV
                                        4K UHD</a>
                                    ,&nbsp;
                                    <a href="/informatique/ordinateur-tablette/tablette/index-c10773.html">Tablette
                                        tactile</a>
                                </li>
                                <li>
                                    <a href="/tv-image-et-son/home-cinema/index-b10869.html"><h3>Home cinema</h3></a>
                                    &nbsp;
                                </li>
                                <li>
                                    <a href="/tv-image-et-son/chaine-hi-fi-platine-vinyle/chaine-hi-fi/index-c10913.html">
                                        <h3>Chaine hifi</h3></a>
                                    &nbsp;
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contenu">
                        <div id="literie" className="bloc">
                            <h2><a href="/chambre-literie/index-a10317.html">Matelas et sommiers</a></h2>
                            <ul>
                                <li>
                                    <a href="/chambre-literie/matelas/toute-l-offre-matelas/index-c11541.html"><h3>Matelas</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/chambre-literie/matelas/toute-l-offre-matelas/index-c11541/NW-2575-dimensions~90x190~cm">Matelas
                                        90x190</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/matelas/toute-l-offre-matelas/index-c11541/NW-2575-dimensions~140x190~cm">Matelas
                                        140x190</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/matelas/toute-l-offre-matelas/index-c11541/NW-2575-dimensions~160x200~cm">Matelas
                                        160x200</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/matelas/toute-l-offre-matelas/index-c11541/NW-2575-dimensions~matelas-bebe~cm">Matelas
                                        bébé</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/matelas/matelas-mousse-matelas-latex/index-c11543/NW-484-technologie~matelas-latex">Matelas
                                        latex</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/matelas/matelas-mousse-matelas-latex/index-c11543/NW-484-technologie~matelas-mousse">Matelas
                                        mousse</a>
                                </li>
                                <li>
                                    <a href="/chambre-literie/sommier/toute-l-offre-sommier/index-c11536.html">
                                        <h3>Sommier</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/chambre-literie/sommier/toute-l-offre-sommier/index-c11536/NW-6444-types-de-sommiers~cadre-a-lattes">Sommier
                                        à lattes</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/sommier/toute-l-offre-sommier/index-c11536/NW-6444-types-de-sommiers~sommier-tapissier">Sommier
                                        tapissier</a>
                                </li>
                                <li>
                                    <a href="/decoration/linge-de-lit/housse-de-couette-parure-de-lit/index-c11283.html">
                                        <h3>Housse de couette</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/decoration/coussin-plaid-pouf/plaid/index-c11292.html">Plaid</a>
                                    ,&nbsp;
                                    <a href="/chambre-literie/matelas/sur-matelas/index-c11544.html">Surmatelas</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contenu">
                        <div id="cuisine" className="bloc agauche">
                            <h2><a href="/cuisine/index-a10315.html">Cuisine et coin repas</a></h2>
                            <ul>
                                <li>
                                    <a href="/cuisine/meuble-de-cuisine/toute-l-offre-meuble-de-cuisine/index-c11624.html">
                                        <h3>Meuble Cuisine</h3></a>
                                    &nbsp;
                                </li>
                                <li>
                                    <a href="/cuisine/meuble-de-cuisine/buffet-de-cuisine/index-c11629.html"><h3>Buffet
                                        cuisine</h3></a>
                                    &nbsp;
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contenu">
                        <div id="decoration" className="bloc">
                            <h2><a href="/decoration/index-a10320.html">Décoration</a></h2>
                            <ul>
                                <li>
                                    <a href="#"><h3>Textile</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/decoration/tapis-paillasson/tapis-salon-et-chambre/index-c11317.html">Tapis</a>
                                    ,&nbsp;
                                    <a href="/decoration/coussin-plaid-pouf/coussin/index-c11290.html">Coussins</a>
                                    ,&nbsp;
                                    <a href="/decoration/rideau-store/rideau/index-c11277.html">Rideaux</a>
                                </li>
                                <li>
                                    <a href="/decoration/luminaire/index-b11235.html"><h3>Luminaires</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/decoration/luminaire/lampe-a-poser/index-c11323.html">Lampe à poser</a>
                                    ,&nbsp;
                                    <a href="/decoration/luminaire/lampe-de-chevet/index-c11325.html">Lampe de
                                        chevet</a>
                                    ,&nbsp;
                                    <a href="/decoration/luminaire/lampadaire/index-c11328.html">Lampadaires</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contenu">
                        <div id="coup" className="bloc agauche">
                            <h2><a href="/code-reduction-bon-plan">Code promo et bon de réduction</a></h2>
                            <ul>
                                <li>
                                    <a href="/themes/offres-de-remboursement-offres-speciales-bons-plans/index-c10498.html">
                                        <h3>Offres de remboursement</h3></a>
                                    &nbsp;
                                </li>
                                <li>
                                    <a href="/themes/premier-prix/index-c10502.html"><h3>Premier prix</h3></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="contenu">
                        <div id="instants_promo" className="bloc">
                            <h2><a
                                href="/themes/promos-et-bons-plans/index-c10444.html">Bons&nbsp;plans&nbsp;et&nbsp;promos</a>
                            </h2>
                            <ul>
                                <li>
                                    <a href="/themes/solution-gain-de-place/index-c10527.html"><h3>Solutions gain de
                                        place</h3></a>
                                    &nbsp;
                                </li>
                                <li>
                                    <a href="/themes/soldes-toute-la-selection/index-c10523.html"><h3>Soldes</h3></a>
                                    &nbsp;:&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968.html">Black Friday</a>
                                    ,&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968/NW-125-type-de-produit~canape">Canapé Black Friday</a>
                                    ,&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968/NW-11791-univers-de-produit~meuble">Meubles Black Friday</a>
                                    ,&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968/NW-125-type-de-produit~lit">Lit Black Friday</a>
                                    ,&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968/NW-125-type-de-produit~lave-linge">Lave linge Black Friday</a>
                                    ,&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968/NW-125-type-de-produit~seche-linge">Sèche-linge Black Friday</a>
                                    ,&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968/NW-125-type-de-produit~micro-ondes">Micro-onde Black Friday</a>
                                    ,&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968/NW-125-type-de-produit~four-encastrable">Four encastrable Black Friday</a>
                                    ,&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968/NW-125-type-de-produit~plaque-induction">Plaque induction Black Friday</a>
                                    ,&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968/NW-125-type-de-produit~congelateur">Congélateur Black Friday</a>
                                    ,&nbsp;
                                    <a href="/themes/black-friday-toute-la-selection/index-c501968/NW-125-type-de-produit~cave-a-vin">Cave à vin Black Friday</a>
                                    ,&nbsp;
                                    <a href="/french-days">French Days</a>
                                    ,&nbsp;
                                    <a href="/themes/noel-toute-la-selection/index-c10515.html">No&euml;l</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HomeDirectory;