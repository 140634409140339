import React from 'react';
import Utils from "@/utils/Default/Utils";

const Liquidation = (props) => {
    let textEventDate = Utils.getMetaInfosValueByCode("textEventDate", props.displayingInfos)
    let discountPercentage = Utils.getMetaInfosValueByCode("discountPercentage", props.displayingInfos)
    let receiptDate = Utils.getMetaInfosValueByCode("receiptDate", props.displayingInfos)
    let receiptNumber = Utils.getMetaInfosValueByCode("receiptNumber", props.displayingInfos)
    let splitedTextDisplayingFront = Utils.getMetaInfosValueByCode("textDisplayingFront", props.displayingInfos).length > 0 ? Utils.getMetaInfosValueByCode("textDisplayingFront", props.displayingInfos) : Utils.getMetaInfosValueByCode("textMobile", props.displayingInfos)
    let textColor = Utils.getMetaInfosValueByCode("textColor", props.displayingInfos).length > 0 ? Utils.getMetaInfosValueByCode("textColor", props.displayingInfos) : Utils.getMetaInfosValueByCode("colorMobile", props.displayingInfos)

    return (
        <div className="animCo liquidation">
            <div className="summary" style={{width: !discountPercentage ? '100%' : null}} >
                <h2 className="opName">{splitedTextDisplayingFront[0]}
                    <span className="big" style={{color: textColor}}>{splitedTextDisplayingFront[1]}</span>
                </h2>
                <p className="date">{textEventDate}</p>
                <p className="description declaration">Récépissé de déclaration n° {receiptNumber} du {receiptDate}</p>
            </div>
            {discountPercentage &&
            <div className="cta">
                <p>jusqu'à<br/>
                    <strong>-{discountPercentage}<sup>%<span>*</span></sup></strong><br/>
                    <span className="condition">*voir conditions en magasin</span></p>
            </div>
            }
        </div>
    );
}

export default Liquidation;